<template>
    <div>
        <b-sidebar backdrop id="StoreTransfer" aria-labelledby="sidebar-no-header-title" no-header width="90rem" style="direction:ltr" right shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl"> 
        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>{{$parent.lang.add_transfer}}</span>
        </div>
        <div @click="hide" id="closeTrans" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>{{$parent.lang.close}}</span>
      </div>
    </div>
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">

                <v-row>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{$parent.lang.trans_type}}</label>
                        <b-input-group>
                        <b-form-select class="selborder" 
                            v-model="trans.trans_type"
                            :options="storeTranstype"
                        ></b-form-select>
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{$parent.lang.from_store}}</label>
                        <b-input-group>
                        <b-form-select class="selborder" 
                            v-model="trans.from_store"
                            :options="soreLists"
                            @change="recalkAll()"
                        ></b-form-select>
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{$parent.lang.to_store}}</label>
                        <b-input-group>
                        <b-form-select class="selborder" 
                            v-model="trans.to_store"
                            :options="soreLists"
                        ></b-form-select>
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{$parent.lang.date}}</label>
                        <b-form-input class="inborder" 
                            v-model="trans.doc_date"
                            type="date"
                        ></b-form-input>
                    </v-col>
                    
                </v-row>
                <v-row>
                    <v-window>{{$parent.lang.bill_detates}}</v-window>
                    <v-simple-table striped hover style="width:100%;">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack">{{$parent.lang.item_code}}</th>
                                    <th class="text-center backBlack">{{$parent.lang.item_name}}</th>
                                    <th class="text-center backBlack">{{$parent.lang.item_unit}}</th>
                                    <th class="text-center backBlack">{{$parent.lang.available_qtty}}</th>
                                    <th class="text-center backBlack">{{$parent.lang.transfer_qtty}}</th>
                                    <th class="text-center backBlack">{{$parent.lang.action}}</th>
                                </tr>
                            </thead>
                            <tbody id="tablerow">
                                <tr v-for="(item,index) in tbrows" :key="index">
                                    <td style="width:100px">{{ item.item_number }}</td>
                                    <td style="width:25%"><b-form-input class="inborder" v-model="tbrows[index].item_name" /></td>
                                    <td>{{ item.unit }}</td>
                                    <td><b-form-input class="inborder" readonly v-model="tbrows[index].av_qty" /></td>
                                    <td><b-form-input class="inborder" v-model="tbrows[index].tr_qty" /></td>
                                    <td style="background:red;border-bottom:10px solid #fff;">
                                        <b-button type="button" variant="danger" class="btn btn-sm" style="width:65px;background:red;border:none" @click="removeItem(index)">{{$parent.lang.delete}}</b-button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b-form-input class="inborder"
                                            v-model="newrow.item_number"
                                            ref="item_number"
                                            @change="getItems()"
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-input class="inborder"
                                            v-model="newrow.item_name"
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-select class="selborder" v-model="newrow.unit" :options="itemUnitsList" @change="getPrices()" />
                                        <b-form-input class="inborder" style="display:none;"
                                            v-model="newrow.unit"
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-input class="inborder"
                                            v-model="newrow.av_qty"
                                            readonly
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-input class="inborder"
                                            v-model="newrow.tr_qty"
                                            @change="calcKit()"
                                        ></b-form-input>
                                    </td>
                                    
                                    <td style="background:green;border-top:10px solid #fff;">
                                        <b-button type="button" class="btn-sm" style="background:green;border:none;" variant="success" @click="addNewRow()">{{$parent.lang.add}}</b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-row>
            </div>
    </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" id="addTrans" variant="success" @click='addPurchase()' class="ma-2" style="width:100px;">{{ SUBMIT_NAME }}</b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default {
    data() {
        return {
            trans: {
                trans_type: 1,
                from_store: 1,
                toolbar: 1,
                doc_date: new Date(),
            },
            itemUnits: [],
            vendorid: '',
            bill_number:'',
            modal: false,
            vendor: '',
            mobile: '',
            bill_date: '',
            vat_number: '',
            vendor_address: '',
            paytype: 1,
            vattype: 2,
            full_total:0,
            full_vat:0,
            full_ftotal:0,
            invtype:1,
            inv_notes:'',
            discount:0,
            tbrows:[],
            newrow:{
                item_number: '',
                item_name: '',
                unit: 1,
                qty: 1,
                av_qty: '',
                tr_qty: '',
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
                unit_id: 1,
            },
            staticOptions: [],
            SUBMIT_NAME:this.$parent.lang.add,
            custodies: [],
            custid: 0,
            stores: []
        }
    },
    methods:{
        getPrices(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getProdDet");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[item_code]',this.newrow.item_number);
            post.append('data[unit]',this.newrow.unit);
            post.append('data[unit_id]',this.newrow.unit_id);
            post.append('data[from_store]',this.trans.from_store);
            let myar = [];
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                this.newrow.av_qty = response.data.results.data[0].current
                this.newrow.tr_qty = response.data.results.data[0].current
            })
        },
        getIT(id){
            if(id == 0){
                if(this.paytype != 0){
                    this.custid = 0
                }
            }else{
                if(this.custid != 0){
                    this.paytype = 0
                }
            }
        },
        enableAdd(){
            document.getElementById('addPurch').disabled = false;
        },
        getPayTypes() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getStaticOptions");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]',1);
            let myar = [];
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.staticOptions = res.results.data;
                this.getStores();
            })
        },
        getStores() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getStores");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',-1);
            let myar = [];
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.stores = res.results.data;
            })
        },
        vendorCheck() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getVendor");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[vendorid]',this.vendorid);
            post.append('data[vendormobile]',this.mobile);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(typeof response.data.results !== 'undefined' 
                    && typeof response.data.results.data !== 'undefined'
                    && response.data.results.data.length != 0){
                        this.vendorid = response.data.results.data.vendorid;
                        this.vendor = response.data.results.data.full_name;
                        this.vat_number = response.data.results.data.vatid;
                        this.vendor_address = response.data.results.data.address;
                        this.mobile = response.data.results.data.mobile;
                    }
            })
        },
        getItems() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getQtyProducts");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[item_number]',this.newrow.item_number);
            post.append('data[from_store]',this.trans.from_store);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                console.log(response.data.results.data[0]);
                if(response.data.results.data.length > 0){
                    if(response.data.results.data[0].item_type == 1){
                        this.newrow.item_number = response.data.results.data[0].item_code;
                        this.newrow.item_name = response.data.results.data[0].item_namear;
                        this.newrow.item_price = 0;
                        this.newrow.unit_id = response.data.results.data[0].main_unit;
                        this.newrow.unit = response.data.results.data[0].main_unit;
                        this.itemUnits = response.data.results.data[0].itemUnits;
                        this.newrow.av_qty = response.data.results.data[0].avQty.qtty;
                        this.newrow.tr_qty = response.data.results.data[0].avQty.qtty;
                    }else{
                        this.newrow.item_number = '';
                        let message = this.$parent.lang.this_item_service_item;
                        this.$snotify.error(message, this.$parent.lang.alert, {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.$parent.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });
                        return false;
                    }
                }else{
                    this.newrow.item_number = '';
                    let message = "الصنف غير متوفر او الصنف خدمي ";
                    this.$snotify.error(message, this.$parent.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    return false;
                }
            })
        },

        calcTotals(){
            this.full_total = 0;
            this.full_vat = 0;
            this.full_ftotal = 0;  
            for(let i = 0; i< this.tbrows.length;i++){
                this.full_total = +this.full_total + +this.tbrows[i].total;
                this.full_vat = +this.full_vat + +this.tbrows[i].vat;
                this.full_ftotal = +this.full_ftotal + +this.tbrows[i].ftotal;
            }
        },
        recalckNew(){
            if(this.newrow.item_price == '' || this.newrow.item_price == 0) return false;
            const vt = this.vattype;
            const total = parseFloat(this.newrow.qty) * parseFloat(this.newrow.item_price);
            const cal = this.$calcVat(total,vt);
            this.newrow.total = cal.tot;
            this.newrow.vat = cal.vat;
            this.newrow.ftotal = cal.ftot;
        },
        recalkAll(){
            let newval = [];
            const vt = this.vattype;
            for(let i = 0;i < this.tbrows.length;i++){
                let currow = this.tbrows[i];
                let qty = currow.qty;
                let price = currow.item_price;
                let total = 0;
                let vat = 0;
                let ftotal = 0;
                currow.total = 0;
                currow.vat = 0;
                currow.ftotal = 0;
                if(qty != '' && price != ''){
                    total = +qty * +price;
                    const cal = this.$calcVat(total,vt);
                    total = cal.tot;
                    vat = cal.vat;
                    ftotal = cal.ftot;
                }
                currow.total = total;
                currow.vat = vat;
                currow.ftotal = ftotal;
                newval.push(currow)
            }
            this.tbrows = newval;
            this.calcTotals();
            this.recalckNew();
        },
        calcKit(){
            const vt = this.vattype;
            const qty = this.newrow.qty;
            const price = this.newrow.item_price;
            let total = 0;
            let vat = 0;
            let ftotal = 0;
            this.newrow.total = 0;
            this.newrow.vat = 0;
            this.newrow.ftotal = 0;
            if(qty != '' && price != ''){
                total = +qty * +price;
                const cal = this.$calcVat(total,vt);
                total = cal.tot;
                vat = cal.vat;
                ftotal = cal.ftot;
            }
            
            this.newrow.total = this.$RoundNum(total);
            this.newrow.vat = this.$RoundNum(vat);
            this.newrow.ftotal = this.$RoundNum(ftotal);
            // // console.log(this.newrow)
        },
        addNewRow(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(this.newrow.qty == 0 || this.newrow.qty == ''){
                this.$snotify.error('الكمية يجب انت تكون رقم غير الصفر', 'خطــأ', {
                    timeout: 2000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return false;
            }
            // if(this.newrow.item_number == ''){
            //     this.$snotify.error('لا يمكن اضافة صنف بدون رقم الصنف', 'خطــأ', {
            //         timeout: 2000,
            //         showProgressBar: true,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //     });
            //     return false;
            // }
            if(this.newrow.item_name == ''){
                this.$snotify.error('لا يمكن اضافة صنف بدون اسم', 'خطــأ', {
                    timeout: 2000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return false;
            }
           
            
            this.tbrows.push(this.newrow);
            this.newrow = {
                item_number: '',
                item_name: '',
                unit: 1,
                qty: 1,
                av_qty: '',
                tr_qty: '',
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
                unit_id: 1,
            }
            // // console.log(this.newrow);
            this.$refs.item_number.focus();
            // this.calcTotals();
        },
        reset(){
            this.newrow = {
                item_number: '',
                item_name: '',
                unit: 1,
                qty: 1,
                av_qty: '',
                tr_qty: '',
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
                unit_id: 1,
            }
            this.trans = {
                trans_type: 1,
                from_store: 1,
                toolbar: 1,
                doc_date: new Date(),
            }
            this.tbrows = []
        },
        removeItem(index){
            this.tbrows.splice(index, 1);
        },
        addPurchase()
        {
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            
            
            
            if(this.tbrows.length < 1){

                let message = this.$parent.lang.empty_invoice_error;
                    this.bill_date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)
                    this.$snotify.error(message, this.$parent.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                return false;
            }
            // // console.log(this.invtype);
            
            document.getElementById('addTrans').disabled = true;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", 'createTrans');
            post.append("auth", this.$cookies.get(this.$COOKIEPhase));
            post.append('data[tbrows]',JSON.stringify(this.tbrows));
            post.append('data[trans]',JSON.stringify(this.trans));
            
            
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                document.getElementById('addTrans').disabled = false;
                const res = response.data;
                // // console.log("Hello are you here",res);

                let message = this.$parent.lang.addes_successfuly;
                    this.bill_date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)
                    this.$snotify.error(message, this.$parent.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                
                this.$parent.getInvoice();
                this.reset()
                //document.getElementById('closeme').click();
            });
        }
    },
    created() {
       //this.addNewRow()
       this.getPayTypes();
    },
    computed: {
        storeTranstype: function(){
            let t = [];
            for(let i=0;i<this.staticOptions.length;i++){
                if(this.staticOptions[i].option_phrase == 'storeTransferType'){
                    t.push({
                        text: this.staticOptions[i][`name_${this.$parent.lang.langname}`],
                        value: this.staticOptions[i].value
                    })
                }
            }
            return t;
        },
        soreLists: function(){
            let t = [];
            for(let i=0;i<this.stores.length;i++){
                t.push({
                    text: this.stores[i][`name_${this.$parent.lang.langname}`],
                    value: this.stores[i].id
                })
            }
            return t;
        },
        itemUnitsList: function(){
            let t = []
            for(let i=0;i<this.itemUnits.length;i++){
                t.push({
                    text: this.itemUnits[i][`name_`+this.$parent.lang.langname],
                    value: this.itemUnits[i].unit_id
                })
            }
            return t;
        },
        cashajls: function() {
            if(this.$store.state.licenseType.activemenu != 'fatora'){
                return [
                    {text: this.$parent.lang.paid_invoice, value: 1},
                    {text: this.$parent.lang.none_paid_invoice, value: 2},
                ]
            }
            else{
                return [
                    {text: this.$parent.lang.paid_invoice, value: 1},
                ]
            }
        },
        custo: function(){
            let t = [{
                text: this.$parent.lang.pay_from_cash,
                value: 0
            }];
            // console.log("custodies",this.custodies);
            for(let i=0;i<this.custodies.length;i++){
                t.push({
                    text: this.custodies[i].name,
                    value: this.custodies[i].id
                })
            }
            return t;
        },
        totals: function(){
            let t = {
                total: 0,
                vat: 0,
                ftotal: 0
            };
            let total = 0;
            for(let i=0;i<this.tbrows.length;i++){
                total = parseFloat(total) + parseFloat(this.tbrows[i].total);
            }
            total = parseFloat(total) - parseFloat(this.discount);
            const vt = this.vattype;
            const tx = this.$calcVat(total,vt);
            t.total = this.$RoundNum(tx.tot);
            t.vat = this.$RoundNum(tx.vat);
            t.ftotal = this.$RoundNum(tx.ftot);
            return t;
        }
    }
}
</script>
